@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
body,
#root {
  margin: 0 !important;
  padding: 0 !important;
  font-family: 'Poppins', sans-serif;
}

nav {
  z-index: 1;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(81, 243, 17, 0.671);
  height: 7vh;
  width: 98.8%;
}

nav .head1 {
  width: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: rgba(255, 255, 255, 0.705);
}

nav .head1 span {
  margin: 0 3px;
  color: #0f661d;
  font-size: 35px;
  font-weight: bolder;
}

nav .head2 {
  width: 70%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: right;
      -ms-flex-pack: right;
          justify-content: right;
  padding: 0 7% 0 0;
}

nav .head2 .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

nav .head2 .links a {
  text-decoration: none;
  color: #c5840b;
  margin: 0 20px;
  font-weight: bold;
}

nav .head2 .links a:hover {
  color: white;
  border-bottom: 1px solid yellow;
}

.section1 {
  background-color: yellow;
  z-index: 10;
}

.section1 h1 {
  font-size: 40px;
  text-align: center;
  padding: 5% 0;
}

.section1 .content {
  margin: 10px 10px;
}
